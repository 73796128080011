.chakra-modal__content-container {
  z-index: 6010 !important;
}

.chakra-modal__overlay {
  z-index: 6010 !important;
}

.chakra-modal__content {
  width: 260px !important;
}
